import React, { Component } from 'react';
import styled from "styled-components"

const SVGIcon = styled.svg`
	width:100%;
	height:100%;
`;
const SVGContainer = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;
	width:30px;
	height:30px;
`;
const left = (
	<SVGIcon viewBox="0 0 512 512">
		<path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
	</SVGIcon>
);
const right = (
	<SVGIcon viewBox="0 0 512 512">
		<path fill="currentColor" d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z">
		</path>
	</SVGIcon>
);
function getIcon(iconCode){
	switch(iconCode){
		case "left":
			return left;
		case "right":
			return right;
		default:
			return null;
	}
}
class QuoteIcon extends Component {

	render() {

		const icon = getIcon(this.props.iconCode)
		return (
			<SVGContainer>
				{icon}
			</SVGContainer>
		);
	}
}






export default QuoteIcon

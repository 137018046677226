import React from 'react';
import styled from "styled-components";
import QuoteIcon from "./QuoteIcon";

const ClientTestimonial = styled.div`
	display:flex;
	flex-direction:column;
`;
const Top = styled.div`
	display:flex;
`;
const Bottom = styled.div`
	padding: 0.5rem 2rem;
	font-style:italic;
	text-align:center;
	font-size:1.2rem;
	line-height:1.2;
	font-weight:700;
	margin-top:0.5rem;
`;
const QuoteMark = styled.div`
	width:50px;
	display:flex;
	justify-content:center;
`;
const Left = styled(QuoteMark)`
	align-items:flex-start;
`;
const Right = styled(QuoteMark)`
	align-items:flex-end;
`;
const Quote = styled.div`
	padding:0 0.5rem;
	text-align:center;
	font-size:1.4rem;
	line-height:1.5;
	font-weight:400;
`;

const Testimonial = ({ testimonial, author }) => {
	return (
		<ClientTestimonial>
			<Top>
				<Left><QuoteIcon iconCode="left" /></Left>
				<Quote dangerouslySetInnerHTML={{__html: testimonial}} />
				<Right><QuoteIcon iconCode="right" /></Right>
			</Top>
			<Bottom>
				{ author }
			</Bottom>
		</ClientTestimonial>
	);
};

export default Testimonial;
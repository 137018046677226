import React, { useState, useEffect } from 'react';
import styled from "styled-components"

import HomeImg from "./HomeImg"
import FadeIn from "../FadeIn";
import { HomeTitle } from "../titles/Title";

const Wrapper = styled.div`
	display:flex;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const HomeText = styled.div`
	min-width:22%;
	max-width:50%;
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		max-width:100%;
		width:100%
	}
`;

const SpanRed = styled.span`
	color:${props => props.theme.red};
`;

const Home = () => {
	const [status, setStatus] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setStatus(1);
		},2000)
	 }, []);

	return (
		<Wrapper>
			<HomeText>
				<HomeTitle />
				<HomeImg classType="mobile" />

				<FadeIn duration="900" delay="2000">
					<p>As a Chartered Architectural Technologist, an Interior Architectural Designer and an Accredited Energy Assessor with a wealth of experience over a wide range of building sectors this business provides planning, architectural design and SAP calculation services. The practice offers a holistic approach to construction industry and a specialism in the science of architecture, building design with a guaranteed personal approach - you can be assured that your project can become a reality using Box&nbsp;<SpanRed>Architectural&nbsp;Services</SpanRed>&nbsp;Ltd.</p>
				</FadeIn>

				
			</HomeText>
			<HomeImg classType="large" />
			
		</Wrapper>
	);
};

export default Home;